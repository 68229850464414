<template>
  <v-dialog persistent 
    v-model="activeModal"
    :max-width="variables.widthModal"
    
    class="modal-cadastro">
    <template v-slot:activator="{}">
      <v-btn
        tile
        :color="variables.colorPrimary"
        dark
        class="mb-2 modal-cadastro-btn-cadastrar"
        @click="openModal"
        :loading="loading"
        v-if="permiteAcao($route, 'add')">Novo cadastro</v-btn>
    </template>
    <v-card>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
          <v-spacer></v-spacer>
          <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>
      
      <v-form>
        <v-card-title class="modal-cadastro-titulo">
          {{ isEdit ? 'Editar Bonificação' : 'Cadastrar Bonificação' }}
        </v-card-title>

        <v-card-text class="modal-cadastro-form">
          <v-row>
            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="4">
              <v-autocomplete
                v-model="item.entityId"
                :items="itensSelect"
                :loading="isLoadingAutoComplete"
                :search-input.sync="searchText"
                :menu-props="{'content-class': 'autocomplete-menu'}"
                @keyup="searchAutoComplete"
                item-text="text"
                item-value="value"
                label="Selecione"
                class="autocomplete">
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      Buscando...
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>  {{ item.text }} </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="4">
              <v-text-field :error-messages="nameErrors" v-model="item.name" label="Nome" @input="$v.item.name.$touch()" @blur="$v.item.name.$touch()" />
            </v-col>

            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="4">
              <v-text-field :error-messages="codErrors" v-model="item.cod" label="Código" @input="$v.item.cod.$touch()" @blur="$v.item.cod.$touch()" />
            </v-col>
            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
              <!-- <v-text-field :error-messages="nameErrors" v-model="item.dtStart" label="Data de Início" @input="$v.item.dtStart.$touch()" @blur="$v.item.dtStart.$touch()" /> -->
              <dateTimerPicker
                label="Data de Início"
                v-model="item.dtStart"
                :datePickerProps="{locale: $store.getters.Locale}"
                :timePickerProps="{format: '24hr'}"
                date-format="dd/MM/yyyy"
                time-format="HH:mm"
                :datetime="datetime"
                :textFieldProps="{errorMessages: dtStartErrors}"
                :key='componentKey1'
                clearText="Limpar" />
            </v-col>
            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
              <!-- <v-text-field :error-messages="nameErrors" v-model="item.dtEnd" label="Data de Fim" @input="$v.item.dtEnd.$touch()" @blur="$v.item.dtEnd.$touch()" /> -->
              <dateTimerPicker
                label="Data do fim do concurso"
                v-model="item.dtEnd"
                :datePickerProps="{locale: $store.getters.Locale, min: dataMinima(item.dtStart)}"
                :timePickerProps="{format: '24hr', min: horaMinimaStart}"
                date-format="dd/MM/yyyy"
                time-format="HH:mm"
                :datetime="datetime"
                :textFieldProps="{errorMessages: dtEndErrors}"
                :key='componentKey2'
                clearText="Limpar"
                @valorDate="compararDataEnd" />
            </v-col>
            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="4">
              <vmoney type="number" :error="sizeLimitErrors" v-model="item.sizeLimit" label="Limite" @input="$v.item.sizeLimit.$touch()" @blur="$v.item.sizeLimit.$touch()" :options="config" />
            </v-col>
            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="4">
              <v-currency-field v-model="item.value" label="Valor" prefix="R$" :error-messages="valueErrors"/>
            </v-col>

            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="4">
              <v-currency-field v-model="item.aliquot" label="Porcentagem" suffix="%" :error-messages="aliquotErrors" :allow-negative="false" :value-range="{min: 0, max: 100}"/>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="modal-cadastro-footer">
          <v-btn :color="variables.colorPrimary" @click="closeModal" class="br-btn br-btn-cancelar">Cancelar</v-btn>
          <v-btn type="submit" :color="variables.colorPrimary" @click.prevent="submit" class="br-btn" :loading="loading">{{ isEdit ? 'Salvar' : 'Cadastrar' }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'
import Events from '@/core/service/events'
import variables from '@/assets/styles/helpers/_variables.scss'
import modalCadastro from '@/views/cadastros/entities/mixins/modalCadastro'

import validacao from './validacao'
const namespaceStore = 'listBonifications'

export default {
  name: 'ModalCadstroBonificacoes',
  mixins: [validationMixin, modalCadastro],
  components: {
    dateTimerPicker: () => import('@/views/components/dateTimePicker'),
    vmoney: () => import('@/views/components/vMoney'),
  },
  data: () => ({
    activeModal: false,
    loading: false,
    horaMinimaStart: '',
    datetime: new Date(),
    componentKey1: 0,
    componentKey2: 1000,
    config: {
      locale: 'pt-BR',
      prefix: '',
      suffix: '',
      length: 100,
      precision: 0
    },
    configAliquot: {
      locale: 'pt-BR',
      prefix: '',
      suffix: '%',
      length: 100,
      precision: 0
    }
  }),
  computed: {
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters(namespaceStore, ['item', 'itensSelect']),
    ...validacao,
    variables: () => variables,
    isEdit () {
      return this.item.id ? !!1 : !!0
    }
  },
  mounted () {
    Events.$on('cadastro::openModalCadastro', () => {
      this.activeModal = true
    })
  },

  watch: {
    'item.cod' (val) {
      this.item.cod = val.toUpperCase()
    }
  },

  methods: {
    ...mapActions(namespaceStore, ['cadastrarItem', 'limparItem', 'getItens', 'editarItem', 'obterItensSelect']),
    openModal () {
      this.isLoadingAutoComplete = true
      this.obterItensSelect().finally(() => this.isLoadingAutoComplete = false)
      this.activeModal = true
    },

    forceRerender() {
      this.componentKey1 += 1  
      this.componentKey2 += 1  
    },

    closeModal () {
      this.activeModal = false
      this.forceRerender()
      this.limparItem()
      this.$v.item.$reset()
    },

    dataMinima (date = '01/01/1900') {
      return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')
    },

    compararDataEnd(payload) {
      if (moment(this.item.dtStart).isSame(moment(payload, 'YYYY-MM-DD'), 'day')) {
        this.horaMinimaStart = moment(this.item.dtStart, 'DD/MM/YYYY HH:mm').format('HH:mm')
        return
      } 

      this.horaMinimaStart = ''
    },

    submit () {
      if (this.$v.item.$invalid) { 
        this.$v.item.$touch()
        return false 
      }

      this.loading = true

      const dados = Object.assign({}, this.item)

      dados.dtStart = moment(dados.dtStart, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm')
      dados.dtEnd =  moment(dados.dtEnd, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm')
      delete dados.created
      delete dados.deleted
      if (this.item.id) {
        dados.id = this.item.id

        this.editarItem(dados).then(() => {
          this.getItens({ page: 1, pageSize: this.$store.getters.ItensPaginacao })
          this.closeModal()
          this.loading = false
          const msg = {
            toggle: true,
            type: 'success',
            msg: 'Edição realizado com sucesso!'
          }
          Events.$emit('snackbarCadastro::msg', msg)
        }).catch(err => {
          this.loading = false
          const msg = {
            toggle: true,
            type: 'error',
            msg: err.msg
          }
          Events.$emit('snackbarCadastro::msg', msg)
        })
      } else {
        this.cadastrarItem(dados).then(() => {
          this.getItens({ page: 1, pageSize: this.$store.getters.ItensPaginacao })
          this.closeModal()
          this.loading = false
          const msg = {
            toggle: true,
            type: 'success',
            msg: 'Cadastro realizado com sucesso!'
          }
          Events.$emit('snackbarCadastro::msg', msg)
        }).catch(err => {
          this.loading = false
          const msg = {
            toggle: true,
            type: 'error',
            msg: err.error
          }
          Events.$emit('snackbarCadastro::msg', msg)
        })
      }
    },
  },

  validations () {
    return  {
      item: {
        aliquot: { required },
        cod: { required },
        name: { required },
        dtEnd: { required },
        dtStart: { required },
        entityId: { required },
        sizeLimit: { required },
        value: { required }
      }
    }
  }
}
</script>

<style lang="scss">
  @import '~@/assets/styles/components/formularios';
  @import '~@/assets/styles/components/modal-cadastro';
</style>