import { errorSnackbar } from '@/core/service/utils'
import { includes } from 'lodash'
import variables from '@/assets/styles/helpers/_variables.scss'
import { mapGetters } from 'vuex'
import { size } from  'lodash'
export default {
  data: () => ({
    activeModal: false,
    loading: false,
    loadingBtnCadastro: false,
    dadosItem: {
      parentId: ''
    },
    isLoadingAutoComplete: false,
    modelAutoComplete: null,
    searchText: null,
    cepValidate: false,
    cepValidateResponsible: false,
    debounce: null
  }),

  watch: {
    'item.id' (val) {
      if (val) {
        this.openModal(this.item)
      }
    }
  },

  computed: {
    ...mapGetters('entities', ['listEntityTypes']),
    isEdit () {
      return this.item.id ? !!1 : !!0
    },

    variables: () => variables,

    itemSelectEdit () {
      if (this.dadosItem.parentId && 
        !includes(this.listaItensSelect, { text: this.dadosItem.parent.name, value: this.dadosItem.parentId } )) {
        return [ ...this.listaItensSelect, { text: this.dadosItem.parent.name, value: this.dadosItem.parentId } ]
      }

      return this.listaItensSelect
    }
  },

  methods: {
    searchAutoComplete() {
      

      clearTimeout(this.debounce)
      const vm = this
      this.debounce = setTimeout(() => {
        if (vm.searchText === null || vm.searchText.length === 0) {
          vm.search('')
          return
        }

        this.search(vm.searchText)
      }, 600)
    },

    search (val) {
      this.isLoadingAutoComplete = true
      this.obterItensSelect({ _filter: { "Entities:jsonData.cod_ilike":`%${val}%` }, pageSize: this.$store.getters.ItensPaginacao })
        .catch(err => {
          errorSnackbar(err.error)
        })
        .finally(() => this.isLoadingAutoComplete = false)
    },

    async openModal (item) {
      if (!item.id) {
        this.loadingBtnCadastro = true
      }
      await Promise.all([
        this.obterItensSelect({ pageSize: this.$store.getters.ItensPaginacao }),
      ])
      .then(() => {
        if (item.id) {
          this.dadosItem = item
        }
        this.activeModal = true
      })
      .catch((err) => {
        errorSnackbar(err.error)
      })
      .finally(() => {
        this.loadingBtnCadastro = false
      })
    },

    closeModal () {
      this.activeModal = false
      this.limparItem()
      this.$v.item.$reset()
      this.search('')
      this.cepValidate = false,
      this.cepValidateResponsible = false
    },

    getEntityType (id) {
      if (!size(this.listEntityTypes)) return ''
      let str = this.listEntityTypes.filter(item => item.id === id)[0].text
      str = str.toLowerCase()
      str = str.charAt(0).toUpperCase() + str.substr(1)

      return str
    }
  }
}