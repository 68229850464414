export default {
  codErrors () {
    const errors = []
    if (!this.$v.item.cod.$dirty) return errors
    !this.$v.item.cod.required && errors.push('O código é obrigatório.')
    return errors
  },

  nameErrors () {
    const errors = []
    if (!this.$v.item.name.$dirty) return errors
    !this.$v.item.name.required && errors.push('O nome é obrigatório.')
    return errors
  },

  aliquotErrors () {
    const errors = []
    if (!this.$v.item.aliquot.$dirty) return errors
    !this.$v.item.aliquot.required && errors.push('A porcentagem é obrigatório.')
    return errors
  },

  dtEndErrors () {
    const errors = []
    if (!this.$v.item.dtEnd.$dirty) return errors
    !this.$v.item.dtEnd.required && errors.push('Data de fim é obrigatório.')
    return errors
  },

  dtStartErrors () {
    const errors = []
    if (!this.$v.item.dtStart.$dirty) return errors
    !this.$v.item.dtStart.required && errors.push('Data de início é obrigatório.')
    return errors
  },

  entityIdErrors () {
    const errors = []
    if (!this.$v.item.entityId.$dirty) return errors
    !this.$v.item.entityId.required && errors.push('Selecione a entidade.')
    return errors
  },

  sizeLimitErrors () {
    const errors = []
    if (!this.$v.item.sizeLimit.$dirty) return errors
    !this.$v.item.sizeLimit.required && errors.push('O limite é obrigatório.')
    return errors
  },

  valueErrors () {
    const errors = []
    if (!this.$v.item.value.$dirty) return errors
    !this.$v.item.value.required && errors.push('O valor é obrigatório.')
    return errors
  }
}
